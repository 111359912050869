
import enums from '@/core/enums/enums';
import {defineComponent, reactive, inject, ref, toRaw} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Api from "@/core/Api";
import {useToast} from "vue-toastification"; // @ is an alias to /src


export default defineComponent({
  name: 'Profile',
  components: {},
  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');
    const passwordValid = ref(true);
    const password2Valid = ref(true);
    const passwordReason = ref('Vigane parool');

    const state: any = reactive({
      password: '',
      password2: '',
    });

    const onSave = () => {
      passwordValid.value = state.password.length > 0;
      password2Valid.value = state.password2.length > 0;
      const passwordsMatch = state.password === state.password2;

      if (!passwordValid.value && !password2Valid.value) {
        passwordReason.value = 'Vigane parool';
      }
      if (!passwordsMatch) {
        passwordValid.value = password2Valid.value = false;
        passwordReason.value = 'Paroolid ei kattu';
      }

      if (!passwordsMatch || !passwordValid.value || !password2Valid.value) {
        return;
      }


      const updateProfile = async (profileData: any) => {
        await Api.updateProfile(profileData)
            .then(() => {
              useToast().success("Profiil uuendatud");
            })
            .catch(() => {
              useToast().error("Viga andmete uuendamisel");
            })
      }
      const data = {password: toRaw(state.password)};
      updateProfile(data);
    }

    const roles = dataStore.state.userRoles;

    return {
      state,
      dataStore,
      enums,
      onSave,
      passwordValid,
      password2Valid,
      passwordReason,
    }
  }
});
