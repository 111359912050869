<template>
  <div class="view Profile container-fluid" style="height: calc(100vh - 56px);">

    <div class="container-sm mt-5" style="max-width: 400px;">

      <div class="row" id="profile-box" @keyup.enter="onSave">
        <div class="col p-4" style="background: #ecf2a2">

          <h4 class="mb-3">Minu profiil</h4>

          <hr class="mb-4">

          <div v-if="!dataStore.state.isGroupUser">
            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="floatingInput" :value="dataStore.state.username" readonly>
              <label for="floatingInput">Email</label>
            </div>

            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="floatingInput2" :value="dataStore.state.firstName" readonly>
              <label for="floatingInput2">Eesnimi</label>
            </div>

            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="floatingInput3" :value="dataStore.state.lastName" readonly>
              <label for="floatingInput3">Perenimi</label>
            </div>
          </div>

          <div v-else>
            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="floatingInput4" :value="dataStore.state.username" readonly>
              <label for="floatingInput">Kasutajanimi</label>
            </div>
          </div>


          <h4 class="mt-5 mb-3">Muuda parooli</h4>

          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword" v-model="state.password"
                   :class="{ 'is-invalid': !passwordValid}">
            <label for="floatingPassword">Uus parool</label>
            <div class="invalid-feedback">{{ passwordReason }}</div>
          </div>

          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword2" v-model="state.password2"
                   :class="{ 'is-invalid': !password2Valid}">
            <label for="floatingPassword2">Uus parool uuesti</label>
            <div class="invalid-feedback">{{ passwordReason }}</div>
          </div>

          <button type="submit" class="btn btn-outline-secondary mb-3" @click="onSave">Salvesta muudatused</button>

        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import enums from '@/core/enums/enums';
import {defineComponent, reactive, inject, ref, toRaw} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Api from "@/core/Api";
import {useToast} from "vue-toastification"; // @ is an alias to /src


export default defineComponent({
  name: 'Profile',
  components: {},
  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');
    const passwordValid = ref(true);
    const password2Valid = ref(true);
    const passwordReason = ref('Vigane parool');

    const state: any = reactive({
      password: '',
      password2: '',
    });

    const onSave = () => {
      passwordValid.value = state.password.length > 0;
      password2Valid.value = state.password2.length > 0;
      const passwordsMatch = state.password === state.password2;

      if (!passwordValid.value && !password2Valid.value) {
        passwordReason.value = 'Vigane parool';
      }
      if (!passwordsMatch) {
        passwordValid.value = password2Valid.value = false;
        passwordReason.value = 'Paroolid ei kattu';
      }

      if (!passwordsMatch || !passwordValid.value || !password2Valid.value) {
        return;
      }


      const updateProfile = async (profileData: any) => {
        await Api.updateProfile(profileData)
            .then(() => {
              useToast().success("Profiil uuendatud");
            })
            .catch(() => {
              useToast().error("Viga andmete uuendamisel");
            })
      }
      const data = {password: toRaw(state.password)};
      updateProfile(data);
    }

    const roles = dataStore.state.userRoles;

    return {
      state,
      dataStore,
      enums,
      onSave,
      passwordValid,
      password2Valid,
      passwordReason,
    }
  }
});
</script>

<style scoped lang="scss">

#profile-box {
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.5);
}

</style>
